<template>
  <section class="px-4">
    <h2 class="text-center text-body-4 text-4xl pt-16">
      Děkujeme za registraci
    </h2>
    <div class="text-center pt-8 text-2xl">
      <p>
        <strong>Zkontrolujte</strong> si Váš email, kde byla zaslána autentifikace k prvnímu přihlášení.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "ConfirmEmailPage"
};
</script>

<style></style>
